<template>
  <div>
    <van-cell title="项目维护" is-link to="/proj-list" />
    <van-cell title="申请查询" is-link @click="toAdminApplList" />
  </div>
</template>

<script>
import { CellGroup, Cell, Dialog } from 'vant';
import { isWxworkMobile } from '../utils';

export default {
  methods: {
    toAdminApplList() {
      if (isWxworkMobile()) {
        Dialog.alert({
          message: '请使用电脑版企业微信查询申请',
          confirmButtonColor: '#1989fa',
          cancelButtonColor: '#1989fa',
        });
        return;
      }
      window.open('/admin/#/appl/list');
    },
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Dialog.Component.name]: Dialog.Component,
  },
}
</script>

<style scoped></style>
