<template>
  <div class="f-appl-list">
    <van-sticky>
      <van-search input-align="center" placeholder="搜索编号或标题" v-model="keyword" @search="onSearch" @clear="onClear" />
      <van-tabs color="#1989fa" v-model="status" @change="onTabsChange">
        <van-tab title="全部" name="all" />
        <van-tab title="待审批" name="pending" />
        <van-tab title="已批准" name="approved" />
        <van-tab title="已驳回" name="rejected" />
        <van-tab title="已撤回" name="revoked" />
      </van-tabs>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-cell v-for="appl in appls" :key="appl.id" :to="`/appl-dets?applId=${appl.id}`" clickable>
          <template #title>
            <div class="title">
              <div>{{ appl.customTitle }}</div>
              <div style="flex-shrink: 0;">
                <van-tag v-if="appl.status === 'pending'" type="primary" plain>待审批</van-tag>
                <van-tag v-else-if="appl.status === 'approved'" type="success" plain>已批准</van-tag>
                <van-tag v-else-if="appl.status === 'rejected'" type="danger" plain>已驳回</van-tag>
                <van-tag v-else-if="appl.status === 'revoked'" type="warning" plain>已撤回</van-tag>
                <van-tag v-else plain>未定义</van-tag>
              </div>
            </div>
          </template>
          <template #label>
            <div class="descr">
              <p>申请编号：{{ appl.id }}&emsp;申请人：{{ appl.creatorName }}</p>
              <p>所属部门：{{ appl.deptName }}</p>
              <p>申请时间：{{ formatDate(appl.createdAt) }}</p>
            </div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import { PullRefresh, List, Cell, Tag, Search, Tab, Tabs, Sticky } from 'vant';
import { handleError } from '../utils';

const limit = 10;

export default {
  data() {
    return {
      keyword: _.get(this.$route.query, 'keyword', ''),
      status: _.get(this.$route.query, 'status', 'all'),
      refreshing: false,
      loading: false,
      finished: false,
      offset: 0,
      appls: [],
    };
  },
  methods: {
    onTabsChange() {
      this.$router.replace({ path: '/appl-list', query: { ...this.$route.query, status: this.status } });
    },
    onClear() {
      const query = { ...this.$route.query };
      delete query.keyword;
      this.$router.replace({ path: '/appl-list', query });
    },
    onSearch() {
      this.$router.replace({ path: '/appl-list', query: { ...this.$route.query, keyword: this.keyword } });
    },
    onRefresh() {
      this.offset = 0;
      this.finished = true;
      this.loading = false;
      this.listAppls();
    },
    onLoad() {
      this.listAppls();
    },
    listAppls() {
      const config = {
        params: {
          keyword: this.keyword,
          status: this.status,
          offset: this.offset,
          limit: limit,
        }
      };
      axios.get('/api/users/~/appls', config)
        .then(response => {
          const { data: { appls = [] } } = response;
          if (this.refreshing) {
            this.appls = appls;
          } else {
            this.appls.push(...appls);
          }
          this.offset += limit;
          this.finished = appls.length < limit;
        })
        .catch(error => {
          handleError(error);
          this.finished = true;
        })
        .then(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
  },
  watch: {
    '$route.query'() {
      this.appls = [];
      this.offset = 0;
      this.finished = false;
      this.loading = true;
      this.listAppls();
    },
  },
  components: {
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
  },
};
</script>

<style scoped>
.descr p {
  margin: 4px 0;
  font-size: 14px;
}

.title {
  display: flex;
  justify-content: space-between;
}
</style>
