<template>
  <fragment>
    <van-field
      :name="name"
      :value="fieldValue"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      @click="toggle"
      clickable
      readonly
    />
    <van-popup v-model="showPicker" position="bottom" get-container="#app" round>
    <van-cascader
      v-model="cascaderValue"
      active-color="#1989fa"
      :title="label"
      :options="options"
      :field-names="{ text: 'name', value: 'id', children: 'children' }"
      @close="toggle"
      @finish="onFinish"
    />
    </van-popup>
  </fragment>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { Field, Popup, Cascader } from 'vant';
import { handleError } from '../../utils';

export default {
  name: 'f-department-widget',
  data() {
    return {
      showPicker: false,
      options: [],
      cascaderValue: '',
      fieldValue: '',
    };
  },
  created() {
    axios.get('/api/depts')
      .then(response => {
        this.options = _.get(response, ['data', 'tree'], []);
      })
      .catch(error => {
        handleError(error);
      });
  },
  methods: {
    onFinish({ selectedOptions }) {
      this.fieldValue = selectedOptions.map((option) => option.name).join(' / ');
      this.toggle();
    },
    toggle() {
      this.showPicker = !this.showPicker;
    },
  },
  props: {
    name: String,
    label: String,
    placeholder: String,
    required: Boolean,
  },
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cascader.name]: Cascader,
  }
}
</script>

<style>

</style>