<template>
  <div class="f-select-valuer">
    <span v-for="(option, index) in selectedOptions" :key="index"><span v-if="index > 0">、</span>{{option.text}}</span>
  </div>
</template>

<script>
import { FieldMixin } from 'vant/es/mixins/field';

export default {
  name: 'f-select-valuer',
  mixins: [FieldMixin],
  computed: {
    selectedOptions() {
      return this.value.filter(option => option.selected);
    },
  },
  props: {
    value: Array,
  },
}
</script>

<style>

</style>