<template>
  <van-cell-group :title="showTitle ? title : ' '">
    <template v-for="child in children">
      <f-input-widget
        v-if="['text', 'digit', 'number', 'money'].includes(child.type)"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :type="child.type"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        @change="onFieldValueChange"
      />
      <f-select-widget
        v-else-if="child.type === 'select'"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        :multiple="child.multiple"
        :options="child.options"
        @change="onFieldValueChange"
      />
      <f-datetime-widget
        v-else-if="['date', 'datetime'].includes(child.type)"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :type="child.type"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        @change="onFieldValueChange"
      />
      <f-file-widget
        v-else-if="child.type === 'file'"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :label="child.title"
        :required="child.required"
        @change="onFieldValueChange"
      />
      <f-department-widget
        v-else-if="child.type === 'department'"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        @change="onFieldValueChange"
      />
      <f-user-widget
        v-else-if="child.type === 'user'"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        :multiple="child.multiple"
        @change="onFieldValueChange"
      />
      <f-appl-widget
        v-else-if="child.type === 'appl'"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        @change="onFieldValueChange"
      />
      <f-proj-widget
        v-else-if="child.type === 'proj'"
        :key="child.id"
        :name="`${name}_${child.id}`"
        :label="child.title"
        :placeholder="child.descr"
        :required="child.required"
        @change="onFieldValueChange"
      />
    </template>
  </van-cell-group>
</template>

<script>
import { CellGroup } from 'vant';
import InputWidget from './InputWidget.vue';
import SelectWidget from './SelectWidget.vue';
import DatetimeWidget from './DatetimeWidget.vue';
import FileWidget from './FileWidget.vue';
import DepartmentWidget from './DepartmentWidget.vue';
import UserWidget from './UserWidget.vue';
import ProjWidget from './ProjWidget.vue';

export default {
  name: 'f-fieldset-widget',
  props: {
    name: String,
    showTitle: Boolean,
    title: String,
    children: Array,
  },
  methods: {
    onFieldValueChange({ name, value }) {
      this.$emit('change', { name, value });
    },
  },
  components: {
    [CellGroup.name]: CellGroup,
    [InputWidget.name]: InputWidget,
    [SelectWidget.name]: SelectWidget,
    [DatetimeWidget.name]: DatetimeWidget,
    [FileWidget.name]: FileWidget,
    [DepartmentWidget.name]: DepartmentWidget,
    [UserWidget.name]: UserWidget,
    [ProjWidget.name]: ProjWidget,
  },
}
</script>

<style>

</style>
