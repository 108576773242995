<template>
  <van-cell-group class="f-user-dept-picker" title=" ">
    <van-cell
      title="所属部门"
      :value="cellValue"
      @click="toggle"
      clickable
    >
      <van-loading
        v-if="options.length === 0"
        size="24px"
      />
    </van-cell>
    <van-popup
      v-model="showPicker"
      position="bottom"
      get-container="#app"
      round
    >
        <van-picker
          title="所属部门"
          :columns="options"
          :default-index="index"
          @confirm="onConfirm"
          @cancel="toggle"
          show-toolbar
        />
      </van-popup>
  </van-cell-group>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import axios from 'axios'
import { CellGroup, Cell, Popup, Picker, Field, Loading } from 'vant';
import { handleError } from '../../utils';

export default {
  name: 'f-user-dept-picker',
  data() {
    return {
      showPicker: false,
      options: [],
      index: 0,
    };
  },
  created() {
    axios.get('/api/users/~/depts')
      .then(response => {
        this.options = _.get(response, ['data', 'userDepts'], []).map((ud, index) => {
          if (this.value !== null) {
            if (this.value === _.last(ud.idPath)) {
              this.index = index;
            }
          } else if (ud.isMain) {
            this.index = index;
            this.$emit('input', _.last(ud.idPath));
          }
          return {
            id: _.last(ud.idPath),
            text: ud.namePath.join(' / '),
            isMain: ud.isMain,
          };
        });
      })
      .catch(error => {
        handleError(error);
      });
  },
  methods: {
    onConfirm(value, index) {
      let drafts = store.get('drafts');
      if (!_.isObject(drafts)) {
        drafts = {};
      }
      const templateKey = `template_${this.$route.query.templateId}`
      if (value.isMain) {
        if (_.has(drafts, [templateKey, 'dept_id'])) {
          delete drafts[templateKey]['dept_id'];
        }
      } else {
        _.set(drafts, [templateKey, 'dept_id'], value.id)
      }
      store.set('drafts', drafts);

      this.index = index;
      this.$emit('input', value.id);
      this.toggle();
    },
    toggle() {
      if (this.options.length > 0) {
        this.showPicker = !this.showPicker;
      }
    },
  },
  computed: {
    cellValue() {
      if (this.options.length > 0) {
        return this.options[this.index].text;
      }
      return '';
    },
  },
  props: {
    value: Number,
  },
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Loading.name]: Loading,
  },
};
</script>

<style scoped>
.f-user-dept-picker .van-cell__title {
  flex: none;
  box-sizing: border-box;
  width: 6.2em;
  margin-right: 12px;
  color: #646566;
  text-align: left;
  word-wrap: break-word;
}
.f-user-dept-picker .van-cell__value {
  overflow: visible;
  text-align: left;
  color: #323233;
}
</style>