<template>
  <div class="f-disp-list">
    <van-sticky>
      <van-search input-align="center" placeholder="搜索编号或标题" v-model="keyword" @search="onSearch" @clear="onKeywordClear">
        <template #left-icon>
          <div style="display: flex;">
            <!-- 日期 -->
            <div style="display: flex; cursor: pointer;" @click="showDatePopup = true">
              <van-icon name="calendar-o" />
              <span style="margin: 0 4px; color: #646566;">{{ date !== '' ? date : '日期' }}</span>
              <van-icon name="cross" size="12px" v-if="date !== ''" @click="onDateClear" />
            </div>
            <van-popup v-model="showDatePopup" position="bottom" get-container="#app">
              <van-datetime-picker type="year-month" title="选择年月" :min-date="new Date(2021, 7)" :max-date="new Date()"
                @confirm="onDateConfirm" @cancel="onDateCancel" />
            </van-popup>
            <!-- 申请人 -->
            <div style="display: flex; cursor: pointer; margin-left: 8px;" @click="selectEnterpriseContact">
              <van-icon name="contact" />
              <span style="margin: 0 4px; color: #646566;">{{ weworkUserName !== '' ? weworkUserName : '申请人' }}</span>
              <van-icon name="cross" size="12px" v-if="weworkUserId !== ''" @click="onContactClear" />
            </div>
            <!-- 类型 -->
            <div style="display: flex; cursor: pointer; margin-left: 8px;" @click="showTemplatePopup = true"
              v-if="!isMobile">
              <van-icon name="label-o" />
              <span style="margin: 0 4px; color: #646566;">{{ templateTitle !== '' ? templateTitle : '类型' }}</span>
              <van-icon name="cross" size="12px" v-if="templateId !== ''" @click="onTemplateClear" />
            </div>
            <van-popup v-model="showTemplatePopup" position="bottom" get-container="#app">
              <div style="max-height: 100vh; display: flex; flex-direction: column;">
                <van-nav-bar title="选择申请类型" left-text="关闭" @click-left="showTemplatePopup = false" />
                <div style="background-color: #f7f8fa; flex: auto; overflow: auto;">
                  <van-cell-group v-for="tg in templateGroups" :key="tg.id" :title="tg.title">
                    <van-cell v-for="t in tg.templates" :key="t.id" :value="t.title" clickable
                      @click="onTemplateConfirm(t)" />
                  </van-cell-group>
                </div>
              </div>
            </van-popup>
          </div>
        </template>
      </van-search>
      <van-tabs color="#1989fa" v-model="status" @change="onTabsChange">
        <van-tab title="待处理" name="pending" :badge="pendingCount > 0 ? pendingCount : null" />
        <van-tab title="已处理" name="disposed" />
        <van-tab title="已撤回" name="revoked" />
        <van-tab title="抄送" name="cc" />
      </van-tabs>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-cell v-for="appl in appls" :key="appl.id" :to="`/disp-dets?applId=${appl.id}`" clickable>
          <template #title>
            <div class="title">
              <div>{{ appl.customTitle }}</div>
              <div style="flex-shrink: 0;">
                <div v-if="appl.status === 'revoked'">
                  <van-tag type="warning" plain>已撤回</van-tag>
                </div>
                <div v-else>
                  <van-tag v-if="appl.resultStatus === 'pending'" type="primary" plain>待审批</van-tag>
                  <van-tag v-else-if="appl.resultStatus === 'approved'" type="success" plain>已批准</van-tag>
                  <van-tag v-else-if="appl.resultStatus === 'rejected'" type="danger" plain>已驳回</van-tag>
                  <van-tag v-else-if="appl.resultStatus === 'approved_other'" type="success" plain>已批准</van-tag>
                  <van-tag v-else-if="appl.resultStatus === 'rejected_other'" type="danger" plain>已驳回</van-tag>
                </div>
              </div>
            </div>
          </template>
          <template #label>
            <div class="descr">
              <p>申请编号：{{ appl.id }}&emsp;申请人：{{ appl.creatorName }}</p>
              <p>所属部门：{{ appl.deptName }}</p>
              <p>申请时间：{{ formatDate(appl.createdAt) }}</p>
            </div>
          </template>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import dayjs from 'dayjs';
import { PullRefresh, List, Cell, CellGroup, Tag, Search, Tab, Tabs, Sticky, Icon, DatetimePicker, Popup, NavBar } from 'vant';
import { handleError, isMobile } from '../utils';

const limit = 10;

export default {
  name: 'f-disp-list',
  data() {
    return {
      date: _.get(this.$route.query, 'date', ''),
      weworkUserId: _.get(this.$route.query, 'weworkUserId', ''),
      weworkUserName: _.get(this.$route.query, 'weworkUserName', ''),
      keyword: _.get(this.$route.query, 'keyword', ''),
      status: _.get(this.$route.query, 'status', 'pending'),
      refreshing: false,
      loading: false,
      finished: false,
      offset: 0,
      appls: [],
      templateGroups: [],
      showDatePopup: false,
      showTemplatePopup: false,
      templateId: _.get(this.$route.query, 'templateId', ''),
      templateTitle: _.get(this.$route.query, 'templateTitle', ''),
      pendingCount: 0,
      scrollTop: 0,
      isMobile: isMobile(),
    };
  },
  mounted() {
    document.getElementById('app').addEventListener('scroll', () => {
      if (this.$route.path === '/disp-list') {
        this.scrollTop = document.getElementById('app').scrollTop;
      }
    });
  },
  activated() {
    document.getElementById('app').scrollTop = this.scrollTop;
    const disposedApplIdStr = sessionStorage.getItem('disposed_appl_id');
    if (disposedApplIdStr) {
      sessionStorage.removeItem('disposed_appl_id');
      const disposedApplId = _.toSafeInteger(disposedApplIdStr);
      this.appls = [...this.appls.filter(appl => appl.id !== disposedApplId)];
      this.pendingCount--;
    }
  },
  beforeDestroy() {
    document.getElementById('app').removeEventListener('scroll');
  },
  methods: {
    onTemplateConfirm(template) {
      this.templateId = `${template.id}`;
      this.templateTitle = template.title;
      this.$router.replace({ path: '/disp-list', query: { ...this.$route.query, templateId: `${template.id}`, templateTitle: template.title } });
      this.showTemplatePopup = false;
    },
    onTemplateClear(event) {
      event.stopPropagation();
      this.templateId = '';
      this.templateTitle = '';
      const query = { ...this.$route.query };
      delete query.templateId;
      delete query.templateTitle;
      this.$router.replace({ path: '/disp-list', query });
    },
    onDateConfirm(value) {
      const date = dayjs(value).format('YYYY-MM');
      this.date = date;
      this.$router.replace({ path: '/disp-list', query: { ...this.$route.query, date } });
      this.showDatePopup = false;
    },
    onDateCancel() {
      this.showDatePopup = false;
    },
    onDateClear(event) {
      event.stopPropagation();
      this.date = '';
      const query = { ...this.$route.query };
      delete query.date;
      this.$router.replace({ path: '/disp-list', query });
    },
    onTabsChange() {
      this.$router.replace({ path: '/disp-list', query: { ...this.$route.query, status: this.status } });
    },
    onKeywordClear() {
      const query = { ...this.$route.query };
      delete query.keyword;
      this.$router.replace({ path: '/disp-list', query });
    },
    onSearch() {
      this.$router.replace({ path: '/disp-list', query: { ...this.$route.query, keyword: this.keyword } });
    },
    onRefresh() {
      this.offset = 0;
      this.finished = true;
      this.loading = false;
      this.listAppls();
    },
    onLoad() {
      this.listAppls();
    },
    listAppls() {
      const config = {
        params: {
          date: this.date,
          weworkUserId: this.weworkUserId,
          keyword: this.keyword,
          templateId: this.templateId,
          status: this.status,
          offset: this.offset,
          limit: limit,
        }
      };
      axios.get('/api/users/~/disps', config)
        .then(response => {
          const { data: { appls = [], pendingCount = 0, extra: { templateGroups = [] } } } = response;
          this.pendingCount = pendingCount;
          this.templateGroups = templateGroups;
          if (this.refreshing) {
            this.appls = appls;
          } else {
            this.appls.push(...appls);
          }
          this.offset += limit;
          this.finished = appls.length < limit;
        })
        .catch(error => {
          handleError(error);
          this.finished = true;
        })
        .then(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    selectEnterpriseContact() {
      const vm = this;
      const selected = [];
      if (vm.weworkUserId !== '') {
        selected.push(vm.weworkUserId);
      }
      // eslint-disable-next-line
      wx.invoke('selectEnterpriseContact', {
        "fromDepartmentId": 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        "mode": "single", // 必填，选择模式，single表示单选，multi表示多选
        "type": ["user"], // 必填，选择限制类型，指定department、user中的一个或者多个
        "selectedDepartmentIds": [], // 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        "selectedUserIds": selected, // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
      }, function (res) {
        if (res.err_msg == "selectEnterpriseContact:ok") {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result) // 由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          vm.weworkUserId = _.get(res.result.userList, [0, 'id'], '');
          vm.weworkUserName = _.get(res.result.userList, [0, 'name'], '');
          vm.$router.replace({ path: '/disp-list', query: { ...vm.$route.query, weworkUserId: vm.weworkUserId, weworkUserName: vm.weworkUserName } });
        }
      });
    },
    onContactClear(event) {
      event.stopPropagation();
      this.weworkUserId = '';
      this.weworkUserName = '';
      const query = { ...this.$route.query };
      delete query.weworkUserId;
      delete query.weworkUserName;
      this.$router.replace({ path: '/disp-list', query });
    },
  },
  watch: {
    '$route'(to, from) {
      if (to.path === '/disp-list' && from.path === '/disp-list' && to.query !== from.query) {
        this.appls = [];
        this.offset = 0;
        this.finished = false;
        this.loading = true;
        this.listAppls();
      }
    }
  },
  components: {
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
  },
};
</script>

<style scoped>
.descr p {
  margin: 4px 0;
  font-size: 14px;
}

.title {
  display: flex;
  justify-content: space-between;
}
</style>
