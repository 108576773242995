<template>
  <div class="f-uploader">
    <div v-for="(item, index) in value" v-bind:key="index" :class="{ item: true, failed: item.status === 'failed' }">
      <div class="van-ellipsis" style="width: 48vw; margin: 4px 0; cursor: pointer; color: #1989fa"
        @click="previewFile(item)">{{
          item.name }}</div>
      <van-loading v-if="item.status === 'uploading'" size="14px" />
      <van-icon v-else name="delete-o" class="delete" @click="onDelete(index)" />
    </div>
    <div class="upload">
      <span>上传</span>
      <input multiple type="file" v-on:change="onChange" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Loading, Icon } from 'vant';
import { FieldMixin } from 'vant/es/mixins/field';
import { handleError, isWxworkMobile } from '../../utils';

export default {
  name: 'f-uploader',
  mixins: [FieldMixin],
  methods: {
    onDelete(index) {
      this.value.splice(index, 1);
    },
    onChange(event) {
      const items = [...this.value];
      Array.from(event.target.files).forEach(file => {
        const item = {
          status: 'uploading',
          name: file.name,
          size: file.size,
          url: '',
        };
        items.push(item);
        this.uploadFile(file, item);
      });
      this.$emit('input', items);
    },
    uploadFile(file, item) {
      item.status = 'uploading';
      const data = new FormData();
      data.append('file', file);
      axios.post('/api/misc/upload-file', data)
        .then((response) => {
          const { data: { size, url } } = response;
          item.status = 'done';
          item.size = size;
          item.url = url;
        })
        .catch((error) => {
          handleError(error);
          item.status = 'failed';
        });
    },
    previewFile({ name, size, url }) {
      const protocol = window.location.protocol;
      if (isWxworkMobile()) {
        // eslint-disable-next-line
        wx.previewFile({
          name,
          size,
          url: protocol + url,
        });
        return;
      }
      window.open(`/api/misc/preview-file?url=${encodeURIComponent(protocol+url)}&name=${encodeURIComponent(name)}`);
    },
  },
  props: {
    value: Array,
  },
  components: {
    [Loading.name]: Loading,
    [Icon.name]: Icon,
  }
}
</script>

<style scoped>
.f-uploader {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.f-uploader .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ebedf0;
  border-radius: 2px;
  padding: 4px 8px;
  margin-bottom: 8px;
}

.f-uploader .item.failed {
  color: #ee0a24;
  border: 1px solid #ee0a24;
}

.f-uploader .item .delete {
  cursor: pointer;
}

.f-uploader .upload {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  border: 1px solid #ebedf0;
  border-radius: 2px;
  padding: 4px 8px;
}

.f-uploader .upload:active {
  background-color: #e5e5e5;
}

.f-uploader .upload input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  overflow: hidden;
  text-indent: -4096px;
}
</style>
