<template>
  <div>
    <van-field style="display: none;" name="dept_id" :value="deptId" />
    <van-field style="display: none;" name="nodes">
      <template #input>
        <f-flow-node-valuer v-model="nodes" />
      </template>
    </van-field>
    <f-user-dept-picker v-model="deptId" />
    <van-cell-group title=" ">
      <van-cell title="审批流程">
        <template #label>
          <van-skeleton title :row="3" :loading="nodes.length === 0">
            <van-steps direction="vertical" active="null">
              <van-step v-for="node in nodes" :key="node.id">
                <div style="display: flex; align-items: center; margin-bottom: 4px;">
                  <span style="margin-right: 4px;">{{ node.title }}</span>
                  <van-tag plain>{{ node.type === 'apv' ? (node.apvMode === 'or' ? '审批·或签' : '审批·会签') : '抄送' }}</van-tag>
                </div>
                <div>
                  <van-tag v-for="(user, index) in node.users" :key="index" :closeable="node.usersType === 'custom'"
                    @close="removeNodeUser(node.id, index)" style="margin: 0 4px 4px 0;" size="medium">
                    {{ user.name }}
                  </van-tag>
                  <van-tag v-if="node.usersType === 'custom'" size="medium" style="cursor: pointer; margin: 0 4px 4px 0;"
                    @click="selectNodeUsers(node.id)">
                    <span>选择成员</span>
                  </van-tag>
                </div>
              </van-step>
            </van-steps>
          </van-skeleton>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import axios from 'axios'
import { CellGroup, Cell, Field, Step, Steps, Tag, Skeleton, Icon } from 'vant';
import UserDeptPicker from './UserDeptPicker.vue';
import FlowNodeValuer from './FlowNodeValuer.vue';
import { handleError } from '../../utils';

export default {
  name: 'f-flow-widget',
  data() {
    return {
      deptId: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, 'dept_id'], null),
      nodes: [],
      lastrseq: '',
    };
  },
  created() {
    if (this.deptId !== null) {
      this.fetchNodes();
    }
  },
  watch: {
    deptId(deptId) {
      if (deptId !== null) {
        this.fetchNodes();
      }
    },
    formvalues() {
      if (this.deptId !== null) {
        this.fetchNodes();
      }
    },
  },
  methods: {
    fetchNodes() {
      this.nodes = [];

      const templateId = this.$route.query.templateId;
      const url = `/api/templates/${templateId}/nodes`;

      const data = _.get(store.get('drafts'), [`template_${templateId}`], null)

      const rseq = _.uniqueId('rseq_');
      this.lastrseq = rseq;

      axios.post(url, { ...data, rseq })
        .then(response => {
          const { data: { rseq, templateNodes = [] } } = response;
          if (this.lastrseq === rseq) {
            this.nodes = templateNodes;
          }
        })
        .catch(error => {
          handleError(error);
        });
    },
    removeNodeUser(nodeId, userIndex) {
      const nodeIndex = _.findIndex(this.nodes, { id: nodeId, usersType: 'custom' });
      this.nodes[nodeIndex].users.splice(userIndex, 1);

    },
    selectNodeUsers(nodeId) {
      const vm = this;
      const nodeIndex = _.findIndex(vm.nodes, { id: nodeId, usersType: 'custom' });
      const nodeUsers = _.isNull(vm.nodes[nodeIndex].users) ? [] : vm.nodes[nodeIndex].users;
      // eslint-disable-next-line
      wx.invoke('selectEnterpriseContact', {
        "fromDepartmentId": 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        "mode": "multi", // 必填，选择模式，single表示单选，multi表示多选
        "type": ["user"], // 必填，选择限制类型，指定department、user中的一个或者多个
        "selectedDepartmentIds": [], // 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        "selectedUserIds": nodeUsers.map(u => u.weworkUserId), // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
      }, function (res) {
        if (res.err_msg == "selectEnterpriseContact:ok") {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result) // 由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          const selectedUserList = res.result.userList; // 已选的成员列表
          vm.nodes[nodeIndex].users = selectedUserList.map(u => ({ weworkUserId: u.id, name: u.name }));
        }
      });
    },
  },
  props: {
    formvalues: Object,
  },
  components: {
    [FlowNodeValuer.name]: FlowNodeValuer,
    [UserDeptPicker.name]: UserDeptPicker,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag,
    [Skeleton.name]: Skeleton,
    [Icon.name]: Icon,
  }
}
</script>

<style scoped></style>
