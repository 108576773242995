import _ from 'lodash';
import axios from 'axios';
import { Toast } from 'vant';

export const handleError = (error) => {
  const { response } = error;
  if (response) {
    const { data, status } = response;
    switch (status) {
      case 401: {

        window.location.href = getLoginURL();
        break;
      }
      case 422: {
        toastFail(_.head(_.values(data.validationErrors)));
        break;
      }
      default: {
        toastFail(data.message ? data.message : data);
        break;
      }
    }
  } else {
    toastFail(error.message);
  }
};

export const toastFail = (message) => {
  Toast.fail({
    message: message,
    duration: 3000,
  });
};

export const wxConfig = () => {
  axios.get('/wework/api/config')
    .then(response => {
      // eslint-disable-next-line
      wx.config(response.data);
    })
    .catch(error => {
      handleError(error);
    });
};

export const getLoginURL = () => {
  const { pathname, search } = window.location;
  const to = encodeURIComponent(pathname + search);
  return isWxwork() ? `/wework/api/oauth-login?to=${to}` : `/wework/api/qrcode-login?to=${to}`;
};

export const isWxworkMobile = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (ua.includes('wxwork') && ua.includes('mobile'));
};

export const isWxwork = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('wxwork');
};

export const isMobile = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('mobile');
};
