<template>
  <van-field
    v-bind:name="name"
    v-bind:label="label"
    v-bind:required="required"
  >
    <template #input>
      <f-uploader v-model="value" />
    </template>
  </van-field>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import { Field } from 'vant';
import Uploader from './Uploader.vue';

export default {
  name: 'f-file-widget',
  data() {
    return {
      value: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, this.name], []),
    };
  },
  watch: {
    value: {
      handler() {
        this.$emit('change', { name: this.name, value: this.value });
      },
      deep: true,
    },
  },
  props: {
    name: String,
    label: String,
    required: Boolean,
  },
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
  }
}
</script>

<style scoped>
</style>
