<template>
  <div class="f-loading" v-if="loading">
    <van-loading vertical>加载中...</van-loading>
  </div>
  <div class="f-appl-form" v-else-if="!error">
    <van-nav-bar fixed placeholder left-arrow left-text="返回" @click-left="goBack" :title="templateTitle" />
    <van-form @submit="onSubmit">
      <van-field v-model="title" label="标题" name="title" required />
      <template v-for="widget in widgets">
        <f-input-widget v-if="['text', 'digit', 'number', 'money'].includes(widget.type)" :key="widget.id"
          :name="`widget_${widget.id}`" :type="widget.type" :label="widget.title" :placeholder="widget.descr"
          :required="widget.required" :nodesum="widget.nodeSum" @change="onFieldValueChange" />
        <f-select-widget v-else-if="widget.type === 'select'" :key="widget.id" :name="`widget_${widget.id}`"
          :label="widget.title" :placeholder="widget.descr" :required="widget.required" :multiple="widget.multiple"
          :options="widget.options" @change="onFieldValueChange" />
        <f-datetime-widget v-else-if="['date', 'datetime'].includes(widget.type)" :key="widget.id"
          :name="`widget_${widget.id}`" :type="widget.type" :label="widget.title" :placeholder="widget.descr"
          :required="widget.required" @change="onFieldValueChange" />
        <f-file-widget v-else-if="widget.type === 'file'" :key="widget.id" :name="`widget_${widget.id}`"
          :label="widget.title" :required="widget.required" @change="onFieldValueChange" />
        <f-department-widget v-else-if="widget.type === 'department'" :key="widget.id" :name="`widget_${widget.id}`"
          :label="widget.title" :placeholder="widget.descr" :required="widget.required" @change="onFieldValueChange" />
        <f-user-widget v-else-if="widget.type === 'user'" :key="widget.id" :name="`widget_${widget.id}`"
          :label="widget.title" :placeholder="widget.descr" :required="widget.required" :multiple="widget.multiple"
          @change="onFieldValueChange" />
        <f-appl-widget v-else-if="widget.type === 'appl'" :key="widget.id" :name="`widget_${widget.id}`"
          :label="widget.title" :placeholder="widget.descr" :required="widget.required" @change="onFieldValueChange" />
        <f-proj-widget v-else-if="widget.type === 'proj'" :key="widget.id" :name="`widget_${widget.id}`"
          :label="widget.title" :placeholder="widget.descr" :required="widget.required" @change="onFieldValueChange" />
        <f-fieldset-widget v-else-if="widget.type === 'fieldset'" :key="widget.id" :name="`widget_${widget.id}`"
          :showTitle="widget.showTitle" :title="widget.title" :children="widget.children" @change="onFieldValueChange" />
        <f-detail-widget v-else-if="widget.type === 'detail'" :key="widget.id" :name="`widget_${widget.id}`"
          :title="widget.title" :children="widget.children" @change="onFieldValueChange" />
      </template>
      <f-flow-widget :formvalues="formvalues" />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import _ from "lodash";
import store from "store";
import axios from "axios";
import {
  Loading,
  NavBar,
  Form,
  CellGroup,
  Field,
  Button,
  Toast,
  Dialog,
} from "vant";
import {
  InputWidget,
  SelectWidget,
  DatetimeWidget,
  FieldsetWidget,
  DetailWidget,
  FlowWidget,
  FileWidget,
  DepartmentWidget,
  UserWidget,
  ApplWidget,
  ProjWidget,
} from "./widgets";
import { handleError } from "../utils";

export default {
  data() {
    return {
      loading: true,
      error: null,
      templateTitle: "",
      widgets: [],
      title: _.get(
        store.get("drafts"),
        [`template_${this.$route.query.templateId}`, "title"],
        ""
      ),
      oriTitle: "",
      formvalues: {},
    };
  },
  created() {
    const templateId = _.get(this.$route.query, "templateId");

    const requests = [
      axios.get(`/api/templates/${templateId}`),
      axios.get(`/api/users/~`),
    ];

    Promise.all(requests)
      .then((results) => {
        const [
          {
            data: { template },
          },
          {
            data: { user },
          },
        ] = results;
        this.templateTitle = _.get(template, "title", "");
        this.widgets = _.get(template, "widgets", []);
        this.oriTitle =
          _.get(user, "name", "") + "的" + _.get(template, "title", "");
        if (this.title === "") {
          this.title =
            _.get(user, "name", "") + "的" + _.get(template, "title", "");
        }
      })
      .catch((error) => {
        handleError(error);
      })
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
    onFieldValueChange(props) {
      const { name, value } = props;
      let drafts = store.get("drafts");
      if (!_.isObject(drafts)) {
        drafts = {};
      }
      const templateId = _.get(this.$route.query, "templateId");
      const templateKey = `template_${templateId}`;
      if (
        _.isEmpty(value) ||
        (_.isArray(value) &&
          _.has(_.first(value), "selected") &&
          value.filter((opt) => opt.selected).length === 0)
      ) {
        if (_.has(drafts, [templateKey, name])) {
          delete drafts[templateKey][name];
        }
        if (_.isEmpty(drafts[templateKey])) {
          delete drafts[templateKey];
        }
      } else {
        _.set(drafts, [templateKey, name], value);
      }
      store.set("drafts", drafts);

      this.formvalues = drafts[templateKey];
    },
    onSubmit(values) {
      Dialog.confirm({
        message: "确认要提交申请吗？",
        confirmButtonColor: "#1989fa",
        cancelButtonColor: "#1989fa",
      })
        .then(() => {
          this.submit(values);
        })
        .catch(() => { });

    },
    submit(values) {
      Toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      const templateId = _.get(this.$route.query, "templateId");
      const applId = _.get(this.$route.query, "applId");
      const url = _.has(this.$route.query, "applId")
        ? `/api/templates/${templateId}/appls/${applId}`
        : `/api/templates/${templateId}/appls`;
      const method = _.has(this.$route.query, "applId") ? "PUT" : "POST";
      axios
        .request({ url, method, data: values })
        .then((response) => {
          let drafts = store.get("drafts");
          delete drafts[`template_${templateId}`];
          store.set("drafts", drafts);
          Toast.success(response.data.message);
          this.$router.go(-1);
        })
        .catch((error) => {
          handleError(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    title(newTitle) {
      let drafts = store.get("drafts");
      if (!_.isObject(drafts)) {
        drafts = {};
      }
      const templateId = _.get(this.$route.query, "templateId");
      const templateKey = `template_${templateId}`;
      if (newTitle === this.oriTitle || newTitle === "") {
        if (_.has(drafts, [templateKey, "title"])) {
          delete drafts[templateKey]["title"];
        }
      } else {
        _.set(drafts, [templateKey, "title"], newTitle);
      }
      if (_.isEmpty(drafts[templateKey])) {
        delete drafts[templateKey];
      }
      store.set("drafts", drafts);
    },
  },
  components: {
    [Loading.name]: Loading,
    [NavBar.name]: NavBar,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Button.name]: Button,
    [InputWidget.name]: InputWidget,
    [DatetimeWidget.name]: DatetimeWidget,
    [SelectWidget.name]: SelectWidget,
    [FileWidget.name]: FileWidget,
    [DepartmentWidget.name]: DepartmentWidget,
    [UserWidget.name]: UserWidget,
    [ApplWidget.name]: ApplWidget,
    [ProjWidget.name]: ProjWidget,
    [FieldsetWidget.name]: FieldsetWidget,
    [DetailWidget.name]: DetailWidget,
    [FlowWidget.name]: FlowWidget,
  },
};
</script>

<style scoped>
.f-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
