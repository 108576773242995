<template>
  <fragment>
    <van-field
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      @click="togglePopup"
      clickable
      readonly
    >
      <template #input>
        <f-appl-valuer v-model="fieldValue" />
      </template>
    </van-field>
    <van-popup v-model="showPopup" position="bottom" get-container="#app" round style="height: 90%;">
      <div class="finder">
        <van-search
          input-align="center"
          placeholder="搜索标题或编号"
          v-model="keyword"
          @search="onSearch"
          @clear="onClear"
        />
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            @load="onLoad"
          >
            <van-cell
              v-for="appl in appls"
              :key="appl.id"
              @click="onSelect(appl)"
              clickable
            >
              <template #title>
                <div class="title">
                  <div>{{ appl.customTitle }}</div>
                  <div style="flex-shrink: 0;">
                    <van-tag v-if="appl.status === 'pending'" type="primary" plain>待审批</van-tag>
                    <van-tag v-else-if="appl.status === 'approved'" type="success" plain>已批准</van-tag>
                    <van-tag v-else-if="appl.status === 'rejected'" type="danger" plain>已驳回</van-tag>
                    <van-tag v-else-if="appl.status === 'revoked'" type="warning" plain>已撤回</van-tag>
                    <van-tag v-else plain>未定义</van-tag>
                  </div>
                </div>
              </template>
              <template #label>
                <div class="descr">
                  <p>申请编号：{{ appl.id }}&emsp;申请人：{{ appl.creatorName }}</p>
                  <p>所属部门：{{ appl.deptName }}</p>
                  <p>申请时间：{{ formatDate(appl.createdAt) }}</p>
                </div>
              </template>
            </van-cell>
          </van-list>
        </van-pull-refresh>
        <div style="padding: 5px 16px;">
          <van-button style="margin-bottom: 5px;" type="default" round block @click="onReset">重置</van-button>
          <van-button type="default" round block @click="togglePopup">取消</van-button>
        </div>
      </div>
    </van-popup>
  </fragment>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import axios from 'axios';
import dayjs from 'dayjs';
import { Field, Popup, Search, PullRefresh, List, Cell, Tag, Button, Dialog } from 'vant';
import { handleError } from '../../utils';
import ApplValuer from './ApplValuer.vue';

const limit = 10;

export default {
  name: 'f-appl-widget',
  data() {
    return {
      showPopup: false,
      fieldValue: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, this.name], []),
      refreshing: false,
      loading: false,
      finished: false,
      offset: 0,
      appls: [],
      keyword: '',
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
    onSelect(appl) {
      const { id, status, customTitle } = appl;
      if (status !== 'approved') {
        Dialog.alert({ message: '仅能关联已批准的申请单' });
        return;
      }
      this.fieldValue = [{ id, title: customTitle }];
      this.togglePopup();
    },
    onReset() {
      this.fieldValue = [];
      this.togglePopup();
    },
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    onSearch() {
      this.appls = [];
      this.offset = 0;
      this.finished = false;
      this.loading = true;
      this.listAppls();
    },
    onClear() {
     this.appls = [];
      this.offset = 0;
      this.finished = false;
      this.loading = true;
      this.listAppls();
    },
    onRefresh() {
      this.offset = 0;
      this.finished = true;
      this.loading = false;
      this.listAppls();
    },
    onLoad() {
      this.listAppls();
    },
    listAppls() {
      axios.get('/api/appls', { params: { keyword: this.keyword, limit: limit, offset: this.offset, widget: true } })
        .then(response => {
          const { data: { appls = [] } } = response;
          if (this.refreshing) {
            this.appls = appls;
          } else {
            this.appls.push(...appls);
          }
          this.offset += limit;
          this.finished = appls.length < limit;
        })
        .catch(error => {
          handleError(error);
          this.finished = true;
        })
        .then(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
  },
  watch: {
    fieldValue () {
      this.$emit('change', { name: this.name, value: this.fieldValue });
    },
  },
  props: {
    name: String,
    label: String,
    placeholder: String,
    required: Boolean,
  },
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Search.name]: Search,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [ApplValuer.name]: ApplValuer,
  }
}
</script>

<style scoped>
.finder {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.finder .van-pull-refresh {
  flex-grow: 1;
  overflow-y: auto;
}
.finder .descr p {
  margin: 4px 0;
  font-size: 14px;
}
.finder .title {
  display: flex;
  justify-content: space-between;
}
</style>