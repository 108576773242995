<template>
  <div>
    <div class="floating-button" @click="onFloatingButtonClick">+</div>
    <van-dialog v-model="showDialog" title="添加项目" confirm-button-color="#1989fa" cancel-button-color="#1989fa"
      show-cancel-button :before-close="onDialogBeforeClose">
      <van-field v-model="title" placeholder="请输入项目名称" type="textarea" maxlength="255" row="1" autosize autofocus
        clearable />
    </van-dialog>
    <van-sticky>
      <van-nav-bar title="项目维护" left-text="返回" left-arrow @click-left="goBack" />
      <van-search input-align="center" placeholder="搜索项目名称" v-model="keyword" @search="onSearch" @clear="onClear" />
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" @load="onLoad">
        <van-swipe-cell v-for="project in projects" :key="project.id">
          <van-cell :title="project.title" />
          <template #right>
            <van-button square type="danger" text="删除" style="height: 100%;" @click="onDelete(project.id)" />
          </template>
        </van-swipe-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import axios from 'axios';
import { PullRefresh, List, Cell, Search, Sticky, Button, Dialog, Field, NavBar, Toast, SwipeCell } from 'vant';
import { handleError } from '../utils';

const limit = 20;

export default {
  data() {
    return {
      showDialog: false,
      title: '',
      keyword: '',
      refreshing: false,
      loading: false,
      finished: false,
      offset: 0,
      projects: [],
    };
  },
  methods: {
    onDelete(projectId) {
      axios.delete(`/api/projects/${projectId}`)
        .then(response => {
          Toast({
            message: response.data.message,
            position: 'bottom',
          });
          this.projects = this.projects.filter(project => project.id !== projectId);
        })
        .catch(error => {
          handleError(error);
        });
    },
    onDialogBeforeClose(action, done) {
      if (action === 'confirm') {
        axios.post('/api/projects', { title: this.title })
          .then(response => {
            const { data: { message } } = response;
            Toast.success(message);
            this.title = '';
            this.refreshing = true;
            this.loading = false;
            this.finished = true;
            this.offset = 0;
            this.projects = [];
            this.listProjects();
            done();
          })
          .catch(error => {
            handleError(error);
            done(false);
          })
          .then(() => { });
      } else {
        this.title = '';
        done();
      }
    },
    onFloatingButtonClick() {
      this.showDialog = true;
    },
    onSearch() {
      this.$router.replace({ path: '/proj-list', query: { ...this.$route.query, keyword: this.keyword } });
    },
    onClear() {
      const query = { ...this.$route.query };
      delete query.keyword;
      this.$router.replace({ path: '/proj-list', query });
    },
    onRefresh() {
      this.offset = 0;
      this.finished = true;
      this.loading = false;
      this.listProjects();
    },
    onLoad() {
      this.listProjects();
    },
    listProjects() {
      const config = {
        params: {
          keyword: this.keyword,
          offset: this.offset,
          limit: limit,
        }
      };
      axios.get('/api/projects', config)
        .then(response => {
          const { data: { projects = [] } } = response;
          if (this.refreshing) {
            this.projects = projects;
          } else {
            this.projects.push(...projects);
          }
          this.offset += limit;
          this.finished = projects.length < limit;
        })
        .catch(error => {
          handleError(error);
          this.finished = true;
        })
        .then(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    '$route.query'() {
      this.projects = [];
      this.offset = 0;
      this.finished = false;
      this.loading = true;
      this.listProjects();
    }
  },
  components: {
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
    [NavBar.name]: NavBar,
    [SwipeCell.name]: SwipeCell,
  },
}
</script>

<style scoped>
.floating-button {
  position: fixed;
  right: 24px;
  bottom: 24px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: #1989fa;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  z-index: 1024;
  cursor: pointer;
}
</style>
