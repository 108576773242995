<template>
  <div id="app">
    <keep-alive include="f-disp-list">
      <router-view />
    </keep-alive>
    <van-tabbar v-show="showTabbar" placeholder route>
      <van-tabbar-item replace to="/" icon="home-o">提交申请</van-tabbar-item>
      <van-tabbar-item replace to="/disp-list" icon="edit">我的审批</van-tabbar-item>
      <van-tabbar-item replace to="/appl-list" icon="description">我的申请</van-tabbar-item>
      <van-tabbar-item replace to="/more" icon="more-o">更多</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';

export default {
  computed: {
    showTabbar() {
      return ['/', '/disp-list', '/appl-list', '/more'].includes(this.$route.path);
    },
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
};
</script>

<style>
#app {
  height: 100vh;
  overflow: auto;
  background-color: #f7f8fa;
}
</style>
