<template>
  <div>
    <van-cell-group v-for="(detail, index) in details" :key="detail">
      <template #title>
        <div style="display: flex; justify-content: space-between;">
          <span>{{ title }} ({{ index + 1 }})</span>
          <van-icon v-show="details.length > 1" v-on:click="removeDetail(index)" name="delete-o"
            style="cursor: pointer;" />
        </div>
      </template>
      <template v-for="child in children">
        <f-input-widget v-if="['text', 'digit', 'number', 'money'].includes(child.type)" :key="child.id"
          :name="`${name}_${index}_${child.id}`" :type="child.type" :label="child.title" :placeholder="child.descr"
          :required="child.required" @change="onFieldValueChange" />
        <f-select-widget v-else-if="child.type === 'select'" :key="child.id" :name="`${name}_${index}_${child.id}`"
          :label="child.title" :placeholder="child.descr" :required="child.required" :multiple="child.multiple"
          :options="child.options" @change="onFieldValueChange" />
        <f-datetime-widget v-else-if="['date', 'datetime'].includes(child.type)" :key="child.id"
          :name="`${name}_${index}_${child.id}`" :type="child.type" :label="child.title" :placeholder="child.descr"
          :required="child.required" @change="onFieldValueChange" />
        <f-file-widget v-else-if="child.type === 'file'" :key="child.id" :name="`${name}_${index}_${child.id}`"
          :label="child.title" :required="child.required" @change="onFieldValueChange" />
        <f-department-widget v-else-if="child.type === 'department'" :key="child.id"
          :name="`${name}_${index}_${child.id}`" :label="child.title" :placeholder="child.descr"
          :required="child.required" @change="onFieldValueChange" />
        <f-user-widget v-else-if="child.type === 'user'" :key="child.id" :name="`${name}_${index}_${child.id}`"
          :label="child.title" :placeholder="child.descr" :required="child.required" :multiple="child.multiple"
          @change="onFieldValueChange" />
        <f-appl-widget v-else-if="child.type === 'appl'" :key="child.id" :name="`${name}_${index}_${child.id}`"
          :label="child.title" :placeholder="child.descr" :required="child.required" @change="onFieldValueChange" />
        <f-proj-widget v-else-if="child.type === 'proj'" :key="child.id" :name="`${name}_${index}_${child.id}`"
          :label="child.title" :placeholder="child.descr" :required="child.required" @change="onFieldValueChange" />
      </template>
      <van-cell v-show="index == details.length - 1" :title="`添加${title}`" v-on:click="addDetail" icon="add-o"
        clickable />
    </van-cell-group>
  </div>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import { Icon, Cell, CellGroup } from 'vant';
import InputWidget from './InputWidget.vue';
import SelectWidget from './SelectWidget.vue';
import DatetimeWidget from './DatetimeWidget.vue';
import FileWidget from './FileWidget.vue';
import DepartmentWidget from './DepartmentWidget.vue';
import UserWidget from './UserWidget.vue';
import ProjWidget from './ProjWidget.vue';

export default {
  name: 'f-detail-widget',
  data() {
    return {
      details: [_.uniqueId('detail_')],
    };
  },
  created() {
    const comp = this;
    const prefix = comp.name + '_'
    const templateId = _.get(comp.$route.query, 'templateId');
    const draft = _.get(store.get('drafts'), `template_${templateId}`, {});
    let maxIndex = 0;
    _.forIn(draft, function (value, key) {
      if (key.startsWith(prefix)) {
        const index = _.toSafeInteger(_.get(key.split('_'), 2));
        if (index > maxIndex) {
          maxIndex = index;
          comp.addDetail();
        }
      }
    });
  },
  methods: {
    onFieldValueChange({ name, value }) {
      this.$emit('change', { name, value });
    },
    removeDetail(index) {
      const comp = this;
      const templateId = _.get(comp.$route.query, 'templateId');
      const templateKey = `template_${templateId}`;
      const drafts = store.get('drafts');
      const oldDraft = _.get(drafts, templateKey, {});
      const newDraft = {};
      for (const oldKey in oldDraft) {
        if (oldKey.startsWith(`${comp.name}_`)) {
          const oldIndex = _.toSafeInteger(_.get(oldKey.split('_'), 2));
          if (oldIndex < index) {
            newDraft[oldKey] = oldDraft[oldKey];
            continue;
          }
          if (oldIndex > index) {
            const newIndex = oldIndex - 1;
            const newKey = _.replace(oldKey, `${comp.name}_${oldIndex}_`, `${comp.name}_${newIndex}_`);
            newDraft[newKey] = oldDraft[oldKey];
            continue;
          }
        }
      }
      drafts[templateKey] = newDraft;
      store.set('drafts', drafts);
      comp.details.splice(index, 1);
      this.$emit('change', { name: '', value: '' });
    },
    addDetail() {
      this.details.push(_.uniqueId('detail_'));
    },
  },
  props: {
    name: String,
    title: String,
    children: Array,
  },
  components: {
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [InputWidget.name]: InputWidget,
    [SelectWidget.name]: SelectWidget,
    [DatetimeWidget.name]: DatetimeWidget,
    [FileWidget.name]: FileWidget,
    [DepartmentWidget.name]: DepartmentWidget,
    [UserWidget.name]: UserWidget,
    [ProjWidget.name]: ProjWidget,
  },
};
</script>

<style></style>
