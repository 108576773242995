<template>
  <fragment>
    <van-field
      :name="name"
      :label="label"
      :placeholder="placeholder"
      :required="required"
      @click="togglePopup"
      clickable
      readonly
    >
      <template #input>
        <f-select-valuer v-model="fieldValue" />
      </template>
    </van-field>
    <van-popup v-model="showPopup" position="bottom" get-container="#app" round>
      <div v-if="multiple">
        <div class="van-picker__toolbar">
          <button type="button" class="van-picker__cancel" @click="onResetMultiple">重置</button>
          <div class="van-ellipsis van-picker__title">{{label}}</div>
          <button type="button" class="van-picker__confirm" @click="onConfirmMultiple">确认</button>
        </div>
        <van-checkbox-group v-model="checkboxGroupValue">
          <van-cell-group>
            <van-cell
              v-for="(option, index) in fieldValue"
              :key="index"
              :title="option.text"
              @click="toggleCheckbox(index)"
              clickable
            >
              <template #right-icon>
                <van-checkbox :name="index" ref="checkboxes" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
      <van-picker
        v-else
        :title="label"
        :columns="fieldValue"
        :default-index="defaultIndex"
        @cancel="onResetSingle"
        @confirm="onConfirmSingle"
        cancel-button-text="重置"
        show-toolbar
      />
    </van-popup>
  </fragment>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import { Field, Popup, Picker, CheckboxGroup, Checkbox, CellGroup, Cell } from 'vant';
import SelectValuer from './SelectValuer.vue';

export default {
  name: 'f-select-widget',
  data() {
    return {
      showPopup: false,
      fieldValue: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, this.name], []),
      checkboxGroupValue: [],
    };
  },
  created() {
    if (this.fieldValue.length > 0) {
      this.fieldValue.forEach((option, index) => {
        if (option.selected) {
          this.checkboxGroupValue.push(index);
        }
      });
      return;
    }
    if (_.isString(_.first(this.options))) {
      this.fieldValue = this.options.map(text => ({ text: text, selected: false }));
      return;
    }
    if (_.isObject(_.first(this.options))) {
      this.fieldValue = [...this.options];
      this.options.forEach((option, index) => {
        if (option.selected) {
          this.checkboxGroupValue.push(index);
        }
      });
      return;
    }
  },
  computed: {
    defaultIndex() {
      const index = _.findIndex(this.fieldValue, { selected: true });
      return index > -1 ? index : 0;
    }
  },
  methods: {
    onResetMultiple() {
      for (let i = 0; i < this.fieldValue.length; i++) {
        this.fieldValue[i].selected = false;
      }
      this.checkboxGroupValue = [];
      this.togglePopup();
    },
    onConfirmMultiple() {
      for (let i = 0; i < this.fieldValue.length; i++) {
        this.fieldValue[i].selected = this.checkboxGroupValue.includes(i);
      }
      this.togglePopup();
    },
    onResetSingle() {
      for (let i = 0; i < this.fieldValue.length; i++) {
        this.fieldValue[i].selected = false;
      }
      this.togglePopup();
    },
    onConfirmSingle(value, index) {
      this.fieldValue = this.fieldValue.map((option, index2) => ({...option, selected: index === index2}));
      this.togglePopup();
    },
    toggleCheckbox(index) {
      this.$refs.checkboxes[index].toggle();
    },
    togglePopup() {
      if (this.multiple) {
        this.checkboxGroupValue = [];
        this.fieldValue.forEach((option, index) => {
        if (option.selected) {
          this.checkboxGroupValue.push(index);
        }
      });
      }
      this.showPopup = !this.showPopup;
    },
  },
  watch: {
    fieldValue: {
      handler() {
        this.$emit('change', { name: this.name, value: this.fieldValue });
      },
      deep: true,
    },
  },
  props: {
    name: String,
    label: String,
    placeholder: String,
    required: Boolean,
    multiple: Boolean,
    options: Array,
  },
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [SelectValuer.name]: SelectValuer,
  },
}
</script>

<style>

</style>
