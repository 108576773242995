<template>
  <fragment>
    <van-field
      v-bind:name="name"
      v-bind:value="value"
      v-bind:label="label"
      v-bind:placeholder="placeholder"
      v-bind:required="required"
      v-on:click="toggle"
      clickable
      readonly
    />
    <van-popup v-model="showPicker" position="bottom" get-container="#app" round>
      <van-datetime-picker
        v-model="currentDate"
        v-bind:min-date="minDate"
        v-bind:type="type"
        v-bind:title="label"
        v-on:cancel="toggle"
        v-on:confirm="confirm"
      />
    </van-popup>
  </fragment>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import dayjs from 'dayjs';
import { Field, Popup, DatetimePicker } from 'vant';

export default {
  name: 'f-datetime-widget',
  data() {
    return {
      showPicker: false,
      value: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, this.name], ''),
      currentDate: new Date(),
      minDate: new Date(2000, 0, 1),
    };
  },
  methods: {
    confirm(time) {
      this.value = this.type === 'date' ? dayjs(time).format('YYYY-MM-DD') : dayjs(time).format('YYYY-MM-DD HH:mm');
      this.toggle();
    },
    toggle() {
      this.showPicker = !this.showPicker;
    },
  },
  watch: {
    value () {
      this.$emit('change', { name: this.name, value: this.value });
    },
  },
  props: {
    name: String,
    type: String,
    label: String,
    placeholder: String,
    required: Boolean,
  },
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
}
</script>

<style>

</style>
