<template>
  <div class="f-user-valuer">
    <span v-for="(user, index) in value" :key="user.weworkUserId"><span v-if="index > 0">、</span>{{user.name}}</span>
  </div>
</template>

<script>
import { Tag } from 'vant';
import { FieldMixin } from 'vant/es/mixins/field';

export default {
  name: 'f-user-valuer',
  mixins: [FieldMixin],
  methods: {
  },
  props: {
    value: Array,
  },
  components: {
    [Tag.name]: Tag,
  },
}
</script>

<style>

</style>