<template>
  <van-field
    :name="name"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    @click="selectEnterpriseContact"
    clickable
    readonly
  >
    <template #input>
      <f-user-valuer v-model="value" />
    </template>
  </van-field>
</template>

<script>
import _ from 'lodash';
import store from 'store';
import { Field } from 'vant';
import UserValuer from './UserValuer.vue';

export default {
  name: 'f-user-widget',
  data() {
    return {
      value: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, this.name], []),
    };
  },
  methods: {
    selectEnterpriseContact() {
      const comp = this;
      // eslint-disable-next-line
      wx.invoke('selectEnterpriseContact', {
        "fromDepartmentId": 0, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
        "mode": this.multiple ? "multi" : "single", // 必填，选择模式，single表示单选，multi表示多选
        "type": ["user"], // 必填，选择限制类型，指定department、user中的一个或者多个
        "selectedDepartmentIds": [], // 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        "selectedUserIds": comp.value.map(user => user.weworkUserId), // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
      }, function(res) {
        if (res.err_msg == "selectEnterpriseContact:ok") {
          if (typeof res.result == 'string') {
            res.result = JSON.parse(res.result) // 由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
          }
          const selectedUserList = res.result.userList; // 已选的成员列表
          comp.value = selectedUserList.map(user => ({ weworkUserId: user.id, name: user.name }));
        }
      });
    },
  },
  watch: {
    value () {
      this.$emit('change', { name: this.name, value: this.value });
    },
  },
  props: {
    name: String,
    label: String,
    placeholder: String,
    required: Boolean,
    multiple: Boolean,
  },
  components: {
    [Field.name]: Field,
    [UserValuer.name]: UserValuer,
  },
}
</script>

<style>

</style>