<template>
  <div class="f-loading" v-if="loading">
    <van-loading vertical>加载中...</van-loading>
  </div>
  <div class="f-appl-dets" v-else-if="!loading">
    <van-nav-bar fixed placeholder :left-arrow="showGoBack" :left-text="showGoBack ? '返回' : ''"
      :title="`${appl.templateTitle}`" @click-left="goBack" />
    <div class="form-data">
      <van-field label="标题" :value="appl.customTitle" type="textarea" rows="1" autosize disabled />
      <van-field label="申请编号" :value="appl.id" disabled />
      <van-field label="申请人" :value="appl.creatorName" disabled />
      <van-field label="所属部门" :value="appl.deptName" disabled />
      <van-field label="申请时间" :value="formatDate(appl.createdAt)" disabled />
      <van-field label="审批状态">
        <template #input>
          <van-tag plain type="primary" v-if="appl.status === 'pending'">待审批</van-tag>
          <van-tag plain type="success" v-else-if="appl.status === 'approved'">已批准</van-tag>
          <van-tag plain type="danger" v-else-if="appl.status === 'rejected'">已驳回</van-tag>
          <van-tag plain type="warning" v-else-if="appl.status === 'revoked'">已撤回</van-tag>
        </template>
      </van-field>
      <template v-for="(widget, index) in appl.formData">
        <van-field v-if="
          ![
            'fieldset',
            'detail',
            'file',
            'user',
            'select',
            'appl',
            'proj',
          ].includes(widget.type)
        " :key="index" :label="widget.title" :value="widget.value" :error-message="
  ['money', 'formula'].includes(widget.type)
    ? moneyChinese(widget.value)
    : ''
" type="textarea" rows="1" autosize disabled />
        <van-field v-else-if="widget.type === 'appl'" :key="index" :label="widget.title" disabled>
          <template #input>
            <span v-for="(as, index) in widget.applValue" :key="index"><span v-if="index > 0">、</span><a
                :href="`?applId=${as.id}`" style="color: #1989fa">{{ as.title }}#{{ as.id }}</a></span>
          </template>
        </van-field>
        <van-field v-else-if="widget.type === 'proj'" :key="index" :label="widget.title" disabled>
          <template #input>
            <span v-for="(ap, index) in widget.projValue" :key="index"><span v-if="index > 0">、</span>{{ ap.title
            }}</span>
          </template>
        </van-field>
        <van-field v-else-if="widget.type === 'select'" :key="index" :label="widget.title" disabled>
          <template #input>
            <span v-for="(option, index) in widget.selectValue.filter(
              (option) => option.selected
            )" :key="index"><span v-if="index > 0">、</span>{{ option.text }}</span>
          </template>
        </van-field>
        <van-field v-else-if="widget.type === 'user'" :key="index" :label="widget.title" disabled>
          <template #input>
            <span v-for="(user, index) in widget.userValue" :key="user.weworkUserId"><span v-if="index > 0">、</span>{{
              user.name }}</span>
          </template>
        </van-field>
        <f-file-widget v-else-if="widget.type === 'file'" :key="index" :appl="appl" :user="user" :widget="widget" />
        <!-- <van-cell
          v-else-if="widget.type === 'file'"
          :key="index"
          :title="widget.title"
          class="file-item"
        >
          <template #label>
            <div style="display: flex; flex-direction: column;">
              <van-button icon="description" v-for="(file, fi) in widget.fileValue" :key="fi" @click="previewFile(file)" style="margin: 4px 0;">
                <div class="van-ellipsis">{{ file.name }}</div>
              </van-button>
            </div>
          </template>
        </van-cell> -->
        <van-cell-group v-else-if="widget.type === 'fieldset'" :key="index" :title="widget.title">
          <template v-for="(fwidget, findex) in widget.fieldsetValue">
            <van-field v-if="
              !['file', 'user', 'select', 'appl', 'proj'].includes(
                fwidget.type
              )
            " :key="findex" :label="fwidget.title" :value="fwidget.value" :error-message="
  ['money', 'formula'].includes(fwidget.type)
    ? moneyChinese(fwidget.value)
    : ''
" type="textarea" rows="1" autosize disabled />
            <van-field v-else-if="fwidget.type === 'proj'" :key="findex" :label="fwidget.title" disabled>
              <template #input>
                <span v-for="(ap, aindex) in fwidget.projValue" :key="aindex"><span v-if="aindex > 0">、</span>{{ ap.title
                }}</span>
              </template>
            </van-field>
            <van-field v-else-if="fwidget.type === 'select'" :key="findex" :label="fwidget.title" disabled>
              <template #input>
                <span v-for="(option, index) in fwidget.selectValue.filter(
                  (option) => option.selected
                )" :key="index"><span v-if="index > 0">、</span>{{ option.text }}</span>
              </template>
            </van-field>
            <van-field v-else-if="fwidget.type === 'user'" :key="findex" :label="fwidget.title" disabled>
              <template #input>
                <span v-for="(user, index) in fwidget.userValue" :key="user.weworkUserId"><span
                    v-if="index > 0">、</span>{{ user.name }}</span>
              </template>
            </van-field>
            <van-cell v-else-if="fwidget.type === 'file'" :key="findex" :title="fwidget.title" class="file-item">
              <template #label>
                <div style="display: flex; flex-direction: column">
                  <van-button icon="description" v-for="(file, fi) in fwidget.fileValue" :key="fi"
                    @click="previewFile(file)" style="margin: 4px 0">
                    <div class="van-ellipsis">{{ file.name }}</div>
                  </van-button>
                </div>
              </template>
            </van-cell>
          </template>
        </van-cell-group>
        <div v-else-if="widget.type === 'detail'" :key="index">
          <template v-for="(dwidget, dindex) in widget.detailValue">
            <van-cell-group :key="dindex" :title="`${widget.title} (${dindex + 1})`">
              <template v-for="(cwidget, cindex) in dwidget">
                <van-field v-if="
                  !['file', 'user', 'select', 'proj'].includes(cwidget.type)
                " :key="cindex" :label="cwidget.title" :value="cwidget.value" :error-message="
  ['money', 'formula'].includes(cwidget.type)
    ? moneyChinese(cwidget.value)
    : ''
" type="textarea" rows="1" autosize disabled />
                <van-field v-else-if="cwidget.type === 'proj'" :key="cindex" :label="cwidget.title" disabled>
                  <template #input>
                    <span v-for="(ap, aindex) in cwidget.projValue" :key="aindex"><span v-if="aindex > 0">、</span>{{
                      ap.title }}</span>
                  </template>
                </van-field>
                <van-field v-else-if="cwidget.type === 'select'" :key="cindex" :label="cwidget.title" disabled>
                  <template #input>
                    <span v-for="(option, index) in cwidget.selectValue.filter(
                      (option) => option.selected
                    )" :key="index"><span v-if="index > 0">、</span>{{ option.text }}</span>
                  </template>
                </van-field>
                <van-field v-else-if="cwidget.type === 'user'" :key="cindex" :label="cwidget.title" disabled>
                  <template #input>
                    <span v-for="(user, index) in cwidget.userValue" :key="user.weworkUserId"><span
                        v-if="index > 0">、</span>{{ user.name }}</span>
                  </template>
                </van-field>
                <van-cell v-else-if="cwidget.type === 'file'" :key="cindex" :title="cwidget.title" class="file-item">
                  <template #label>
                    <div style="display: flex; flex-direction: column">
                      <van-button icon="description" v-for="(file, fi) in cwidget.fileValue" :key="fi"
                        @click="previewFile(file)" style="margin: 4px 0">
                        <div class="van-ellipsis">{{ file.name }}</div>
                      </van-button>
                    </div>
                  </template>
                </van-cell>
              </template>
            </van-cell-group>
          </template>
        </div>
      </template>
    </div>
    <!-- end of .form-data -->
    <div class="flow">
      <van-cell-group title=" ">
        <van-cell title="审批流程">
          <template #label>
            <van-steps direction="vertical" finish-icon="checked" :active-icon="stepsActiveIcon" :active="stepsActive"
              :active-color="stepsActiveColor">
              <van-step v-for="(node, index) in appl.nodes" :key="index">
                <div style="display: flex; align-items: center; margin-bottom: 4px">
                  <span style="margin-right: 4px">{{ node.title }}</span>
                  <van-tag plain>{{
                    node.type === "apv"
                    ? node.apvMode === "or"
                      ? "审批·或签"
                      : "审批·会签"
                    : "抄送"
                  }}</van-tag>
                </div>
                <div style="display: flex; flex-direction: column">
                  <div v-for="(result, rindex) in node.results" :key="rindex" style="margin: 4px 0">
                    <van-tag size="medium" :type="
                      result.status === 'approved'
                        ? 'success'
                        : result.status === 'rejected'
                          ? 'danger'
                          : 'default'
                    ">
                      {{ result.user.name }}
                    </van-tag>
                    <span class="comment">{{ result.comment }}</span>
                    <span style="margin-left: 4px; color: #969799">{{
                      result.disposedAt ? formatDate(result.disposedAt) : ""
                    }}</span>
                  </div>
                </div>
              </van-step>
            </van-steps>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <!-- end of .flow -->
    <template v-if="showDispBar">
      <div class="disp-bar-holder" />
      <div class="disp-bar van-hairline--top">
        <van-button v-if="showDispButtons" icon="success" size="large" plain @click="onDisp('approve')">批准</van-button>
        <van-button v-if="showDispButtons" icon="cross" size="large" plain @click="onDisp('reject')">驳回</van-button>
        <van-button size="large" plain @click="onPrint">
          <template #icon>
            <img src="../assets/printer.svg" style="display: block" />
          </template>
          <span>打印</span>
        </van-button>
        <van-button icon="share-o" size="large" plain @click="onShare">转发</van-button>
      </div>
      <van-dialog v-model="showDialog" title="审批意见" confirm-button-color="#1989fa" cancel-button-color="#1989fa"
        show-cancel-button @confirm="onConfirm">
        <van-field v-model="comment" placeholder="请输入审批意见" type="textarea" maxlength="65535" show-word-limit autosize />
      </van-dialog>
    </template>
    <!-- end of .disp-bar -->
    <template v-if="showApplBar">
      <div class="appl-bar-holder" />
      <div class="appl-bar van-hairline--top">
        <van-button v-if="showApplButtons && appl.status === 'pending'" icon="bullhorn-o" size="large" plain
          @click="onUrge">催办</van-button>
        <van-button v-if="
          showApplButtons &&
          (appl.status === 'revoked' || appl.status === 'rejected')
        " icon="edit" size="large" plain @click="onEdit">编辑</van-button>
        <van-button v-if="
          showApplButtons &&
          (appl.status === 'revoked' || appl.status === 'rejected')
        " icon="delete-o" size="large" plain @click="onDelete">删除</van-button>
        <van-button v-if="showApplButtons && showRevokeButton" icon="revoke" size="large" plain
          @click="onRevoke">撤回</van-button>
        <van-button size="large" plain @click="onPrint">
          <template #icon>
            <img src="../assets/printer.svg" style="display: block" />
          </template>
          <span>打印</span>
        </van-button>
        <van-button icon="share-o" size="large" plain @click="onShare">转发</van-button>
      </div>
    </template>
    <!-- end of .appl-bar -->
  </div>
</template>

<script>
import _ from "lodash";
import store from "store";
import axios from "axios";
import dayjs from "dayjs";
import nzhcn from "nzh/cn";
import {
  Loading,
  NavBar,
  CellGroup,
  Cell,
  Field,
  Steps,
  Step,
  Tag,
  Button,
  Dialog,
  Toast,
  Icon,
} from "vant";
import { handleError, isWxworkMobile } from "../utils";
import FileWidget from "./editable-widgets/FileWidget.vue";

export default {
  data() {
    return {
      loading: false,
      showDialog: false,
      action: null,
      comment: "",
      appl: null,
      user: null,
    };
  },
  created() {
    this.fetchAppl();
  },
  computed: {
    showRevokeButton() {
      if (this.appl?.status === "revoked") {
        return false;
      }
      const node = _.find(_.get(this.appl, "nodes", []), { type: "apv" });
      const results = _.get(node, "results", []);
      return !_.find(results, (r) => r.status !== "pending");
    },
    showApplButtons() {
      return this.user?.id === this.appl?.creatorId;
    },
    showApplBar() {
      return this.$route.path === "/appl-dets";
    },
    showDispBar() {
      return this.$route.path === "/disp-dets";
    },
    showDispButtons() {
      const node = _.find(_.get(this.appl, "nodes", []), { status: "pending" });
      const result = _.find(_.get(node, "results", []), {
        status: "pending",
        userId: this.user.id,
      });
      return !!result;
    },
    stepsActiveIcon() {
      const status = _.get(this.appl, "status");
      switch (status) {
        case "pending":
          return "clock";
        case "rejected":
          return "clear";
        default:
          return "checked"; // approveed
      }
    },
    stepsActiveColor() {
      return _.get(this.appl, "status") === "rejected" ? "#ee0a24" : "#07c160";
    },
    stepsActive() {
      const index = _.findLastIndex(_.get(this.appl, "nodes", []), (node) => {
        return ["pending", "approved", "rejected"].includes(node.status);
      });
      return index > -1 ? index : 0;
    },
    showGoBack() {
      return true;
    },
  },
  methods: {
    moneyChinese(value) {
      return nzhcn.toMoney(value, { outSymbol: false });
    },
    previewFile({ name, size, url }) {
      const protocol = window.location.protocol;
      if (isWxworkMobile()) {
        // eslint-disable-next-line
        wx.previewFile({
          name,
          size,
          url: protocol + url,
        });
        return;
      }
      window.open(`/api/misc/preview-file?url=${encodeURIComponent(protocol+url)}&name=${encodeURIComponent(name)}`);
    },
    onShare() {
      const appl = this.appl;
      const date = dayjs(appl.createdAt).format("YYYY年M月D日H时m分");
      // eslint-disable-next-line
      wx.invoke("shareAppMessage", {
        title: appl?.customTitle, // 分享标题
        desc: `${appl.creatorName}于${date}提交的${appl.templateTitle}`, // 分享描述
        link: window.location.href, // 分享链接
        imgUrl: `${window.location.protocol}${appl.icon}?x-oss-process=image/resize,m_fill,h_88,w_88`, // 分享封面
      });
    },
    onPrint() {
      if (isWxworkMobile()) {
        Dialog.alert({
          message: "请使用电脑版企业微信进行打印",
          confirmButtonColor: "#1989fa",
          cancelButtonColor: "#1989fa",
        });
        return;
      }
      window.open(`/api/appls/${this.appl?.id}/print`);
    },
    onUrge() {
      Dialog.confirm({
        message: "确认要催办吗？",
        confirmButtonColor: "#1989fa",
        cancelButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
          });
          const applId = this.$route.query.applId;
          axios
            .post(`/api/appls/${applId}/urge`)
            .then((response) => {
              Toast.success(response.data.message);
            })
            .catch((error) => {
              handleError(error);
            });
        })
        .catch(() => { });
    },
    onDelete() {
      Dialog.confirm({
        message: "确认要删除吗？",
        confirmButtonColor: "#1989fa",
        cancelButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
          });
          const applId = this.$route.query.applId;
          axios
            .delete(`/api/users/~/appls/${applId}`)
            .then((response) => {
              Toast.success(response.data.message);
              this.$router.replace("/appl-list");
            })
            .catch((error) => {
              handleError(error);
            });
        })
        .catch(() => { });
    },
    onEdit() {
      const { id, templateId, createdAt } = this.appl;
      const shed = dayjs("2021-08-13T19:30:00.000+08:00");
      if (dayjs(createdAt).isBefore(shed)) {
        Dialog({
          message: `${shed.format("YYYY年M月D日H时m分")}前提交的申请不支持编辑`,
          confirmButtonColor: "#1989fa",
          cancelButtonColor: "#1989fa",
        });
        return;
      }
      const draft = {
        title: this.appl.customTitle,
        dept_id: this.appl.deptId,
      };
      this.buildDraftWidgets(draft, this.appl.formData);
      let drafts = store.get("drafts");
      if (!_.isObject(drafts)) {
        drafts = {};
      }
      drafts[`template_${templateId}`] = draft;
      store.set("drafts", drafts);
      this.$router.push(`/appl-form?templateId=${templateId}&applId=${id}`);
    },
    buildDraftWidgets(draft, fields) {
      fields.forEach((fld) => {
        switch (fld.type) {
          case "detail":
            fld.detailValue.forEach((detailFields) => {
              this.buildDraftWidgets(draft, detailFields);
            });
            break;
          case "fieldset":
            this.buildDraftWidgets(draft, fld.fieldsetValue);
            break;
          case "proj":
            if (!_.isEmpty(fld.projValue)) {
              draft[fld.fieldName] = fld.projValue;
            }
            break;
          case "select":
            if (!_.isEmpty(fld.selectValue)) {
              draft[fld.fieldName] = fld.selectValue;
            }
            break;
          case "appl":
            if (!_.isEmpty(fld.applValue)) {
              draft[fld.fieldName] = fld.applValue;
            }
            break;
          case "user":
            if (!_.isEmpty(fld.userValue)) {
              draft[fld.fieldName] = fld.userValue;
            }
            break;
          case "file":
            if (!_.isEmpty(fld.fileValue)) {
              draft[fld.fieldName] = fld.fileValue;
            }
            break;
          default:
            if (!_.isEmpty(fld.value)) {
              draft[fld.fieldName] = fld.value;
            }
            break;
        }
      });
    },
    onRevoke() {
      Dialog.confirm({
        message: "确认要撤回吗？",
        confirmButtonColor: "#1989fa",
        cancelButtonColor: "#1989fa",
      })
        .then(() => {
          Toast.loading({
            message: "加载中...",
            forbidClick: true,
            duration: 0,
          });
          const applId = this.$route.query.applId;
          axios
            .post(`/api/appls/${applId}/revoke`)
            .then((response) => {
              Toast.success(response.data.message);
              this.fetchAppl();
            })
            .catch((error) => {
              handleError(error);
            });
        })
        .catch(() => { });
    },
    onConfirm() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      const applId = this.$route.query.applId;
      axios
        .post(`/api/appls/${applId}/${this.action}`, { comment: this.comment })
        .then((response) => {
          sessionStorage.setItem("disposed_appl_id", _.toString(applId));
          Toast.success(response.data.message);
          this.fetchAppl();
        })
        .catch((error) => {
          handleError(error);
        });
    },
    onDisp(action) {
      this.action = action;
      this.comment = "";
      this.showDialog = true;
    },
    fetchAppl() {
      this.loading = true;
      const applId = this.$route.query.applId;
      axios
        .get(`/api/appls/${applId}`)
        .then((response) => {
          const {
            data: { appl, user },
          } = response;
          this.appl = appl;
          this.user = user;
        })
        .catch((error) => {
          handleError(error);
        })
        .then(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  components: {
    [Loading.name]: Loading,
    [NavBar.name]: NavBar,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Icon.name]: Icon,
    [FileWidget.name]: FileWidget,
  },
};
</script>

<style>
.f-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-appl-dets .disp-bar,
.f-appl-dets .appl-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1024;
  display: flex;
}

.f-appl-dets .disp-bar button,
.f-appl-dets .appl-bar button {
  flex: 1;
  border: 0 none;
}

.f-appl-dets .disp-bar-holder,
.f-appl-dets .appl-bar-holder {
  height: 50px;
}

.f-appl-dets .file-item .van-button {
  text-align: left;
}

.f-appl-dets .file-item .van-button__content {
  justify-content: flex-start;
}

.f-appl-dets .file-item .van-ellipsis {
  width: 72vw;
}

.f-appl-dets .comment {
  margin-left: 4px;
  color: #323233;
}

.f-appl-dets .van-field__error-message {
  color: #969799;
}

.f-appl-dets .van-field--disabled .van-field__label {
  color: #646566;
}

.f-appl-dets .van-field__control:disabled {
  color: #323233;
  -webkit-text-fill-color: #323233;
}

.f-appl-dets .van-field__control:disabled {
  cursor: text;
}
</style>
