import Vue from "vue";
import Fragment from "vue-fragment";
import VueRouter from "vue-router";
import "@vant/touch-emulator";
import App from "./App.vue";
import Home from "./components/Home.vue";
import ApplForm from "./components/ApplForm.vue";
import DispList from "./components/DispList.vue";
import ApplDets from "./components/ApplDets.vue";
import ApplList from "./components/ApplList.vue";
import More from "./components/More.vue";
import ProjList from "./components/ProjList.vue";
import { wxConfig } from "./utils";

Vue.config.productionTip = false;

Vue.use(Fragment.Plugin);

Vue.use(VueRouter);

const routes = [
  { path: "/", component: Home },
  { path: "/appl-form", component: ApplForm },
  { path: "/disp-list", component: DispList },
  { path: "/disp-dets", component: ApplDets },
  { path: "/appl-list", component: ApplList },
  { path: "/appl-dets", component: ApplDets },
  { path: "/more", component: More },
  { path: "/proj-list", component: ProjList },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((to, from) => {
  wxConfig();
  if (from === VueRouter.START_LOCATION) {
    // eslint-disable-next-line
    wx.ready(() => {
      // eslint-disable-next-line
      wx.hideOptionMenu();
    });
  }
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
