<template>
  <div class="f-proj-valuer">
    <span v-for="(project, index) in value" :key="project.id"><span v-if="index > 0">、</span>{{project.title}}</span>
  </div>
</template>

<script>
import { FieldMixin } from 'vant/es/mixins/field';

export default {
  name: 'f-proj-valuer',
  mixins: [FieldMixin],
  methods: {
  },
  props: {
    value: Array,
  },
}
</script>

<style>

</style>
