<template>
  <div>Aha</div>
</template>

<script>
import { FieldMixin } from 'vant/es/mixins/field';

export default {
  name: 'f-flow-node-valuer',
  mixins: [FieldMixin],
  props: {
    value: Array,
  },
}
</script>

<style>

</style>