<template>
  <van-field v-model="value" :name="name" :type="type === 'money' ? 'number' : type" :label="label"
    :placeholder="placeholder" :required="required" format-trigger="onBlur"
    :formatter="type === 'money' ? moneyFormatter : null"
    :rules="type === 'money' ? [{ validator: moneyValidator, trigger: 'onChange', message: '这不是一个有效的金额' }] : []"
    :error-message="type === 'money' ? moneyChinese : ''" class="f-input-widget" />
</template>

<script>
import _ from 'lodash';
import store from 'store';
import nzhcn from 'nzh/cn';
import { Field } from 'vant';

export default {
  name: 'f-input-widget',
  data() {
    return {
      value: _.get(store.get('drafts'), [`template_${this.$route.query.templateId}`, this.name], ''),
    };
  },
  computed: {
    moneyChinese() {
      return nzhcn.toMoney(this.value, { outSymbol: false });
    },
  },
  methods: {
    moneyFormatter(value) {
      return /^\d+(\.\d{1,2})?$/.test(value) ? parseFloat(value).toFixed(2).toString() : value;
    },
    moneyValidator(value) {
      return value.length > 0 ? /^\d+(\.\d{1,2})?$/.test(value) : true;
    },
  },
  watch: {
    value() {
      this.$emit('change', { name: this.name, value: this.value });
    },
  },
  props: {
    name: String,
    type: String,
    label: String,
    placeholder: String,
    required: Boolean,
  },
  components: {
    [Field.name]: Field,
  },
}
</script>

<style>
.f-input-widget .van-field__error-message {
  color: #969799;
}
</style>
