<template>
  <van-field :label="widget.title">
    <template #input>
      <f-uploader v-model="widget.fileValue" :editableNodeUUIDs="widget.editableNodeUUIDs" :uuid="widget.uuid"
        :appl="appl" :user="user" />
    </template>
  </van-field>
</template>

<script>
import { Field } from 'vant';
import Uploader from './Uploader.vue';

export default {
  name: 'f-file-widget',
  props: {
    appl: Object,
    user: Object,
    widget: Object,
  },
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
  }
}
</script>

<style scoped></style>
