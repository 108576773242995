<template>
  <div class="f-appl-valuer">
    <span v-for="(appl, index) in value" :key="appl.id"><span v-if="index > 0">、</span>{{appl.title}}#{{appl.id}}</span>
  </div>
</template>

<script>
import { FieldMixin } from 'vant/es/mixins/field';

export default {
  name: 'f-appl-valuer',
  mixins: [FieldMixin],
  methods: {
  },
  props: {
    value: Array,
  },
}
</script>

<style>

</style>